import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const ProfileImage = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 144, height: 144) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            linkedin
          }
        }
      }
    }
`);
  return (
      <div className="relative inline-block leading-none">
        <Image
          className="rounded-full relative z-10"
          fixed={data.avatar.childImageSharp.fixed}
          alt="Matthias Lexer"
        />
        <div className="absolute inset-0 rounded-full bg-lead z-0 transform translate-x-12"></div>
      </div>
    )
}


export default ProfileImage
