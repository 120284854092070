import React from "react"
import { Link } from "gatsby"
import { FaHome, FaHandPeace } from "react-icons/fa"

const Header = ({ isIndex }) => {
    return (
        <header className="flex justify-between p-4 lg:px-8">
            <Link to='/'>
                <span className="inline-flex w-14 h-14 lg:mt-4 font-header font-bold text-xl justify-center items-center text-center text-front border-2 border-solid border-front rounded-full">
                    ML
                </span>
            </Link>
            { isIndex && (
                <Link
                className="flex w-14 h-14 font-header font-semibold px-2 bg-lead rounded-full text-lead-text justify-center items-center leading-tight lg:w-auto lg:h-auto lg:px-6 lg:py-2 lg:rounded-lg lg:self-start lg:mt-4 hover:opacity-75 transition-opacity duration-150"
                    to='about'
                >
                    <FaHandPeace />&nbsp;About Me
                </Link>
            )}
            { !isIndex && (
                <Link
                className="flex w-14 h-14 font-header font-semibold px-2 bg-lead rounded-full text-lead-text justify-center items-center leading-tight lg:w-auto lg:h-auto lg:px-6 lg:py-2 lg:rounded-lg lg:self-start lg:mt-4 hover:opacity-75 transition-opacity duration-150"
                    to='/'
                >
                    <FaHome />&nbsp;Blog
                </Link>
            )}
        </header>
  )
}

export default Header
