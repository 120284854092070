import React from "react"
import Location from "./location"
import ProfileImage from "./profile-image"

const Sidebar = () => {
  return (
    <aside className="w-full lg:w-1/3 lg:border-r border-line lg:px-6 xl:px-12">
      <div className="flex flex-col h-full justify-between">
        <div>
          <h2 className="font-header font-light text-front text-2xl leading-none mb-4">
            Full Stack Developer
          </h2>
          <h1 className="font-header font-black text-front text-5xl leading-none break-words mb-6">
            Matthias Lexer
          </h1>
          <ProfileImage />
          <br />
          <Location />
        </div>
      </div>
    </aside>
  )
}

export default Sidebar
