import React from "react"
import { Helmet } from "react-helmet"

const CustomFonts = () => {
  return (
    <Helmet>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Cormorant:wght@500&family=Source+Sans+Pro:wght@300&display=swap"
      />
    </Helmet>
  )
}

export default CustomFonts
