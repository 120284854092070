import React from "react"
import Footer from "./footer"
import Header from "./header"
import Sidebar from "./sidebar/sidebar"
import CustomFonts from './custom-fonts/custom-fonts'

class Layout extends React.Component {
  render() {
    const { children } = this.props
    
    return (
      <div className="antialiased bg-back leading-normal font-text text-front flex flex-col min-h-screen">
        <CustomFonts />

        <Header isIndex={this.props.location.pathname === '/'}></Header>
        <div className="flex-auto md:max-w-screen-sm lg:max-w-screen-xl mx-auto px-4 flex flex-wrap pt-4 my-8">
          <Sidebar />
          <main className="lg:w-2/3 lg:pl-8 xl:pl-12">
            {children}
            </main>
        </div>
        
        <Footer></Footer>
      </div>
    )
  }
}

export default Layout
