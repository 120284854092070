import React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <footer className="mt-8 pt-8 pb-8">
    <div className="md:max-w-screen-sm lg:max-w-screen-xl mx-auto px-4 flex items-center">
      <div className="w-2/3 font-header text-xs">
        &copy; {new Date().getFullYear()}
        {" "}
        made with ❤️ in the alps
      </div>
      <div className="w-1/3 text-right font-header text-xs">
        <Link to="/impressum">Impressum</Link>
      </div>
    </div>
  </footer>
)

export default Footer
